/**
 * @link https://css-tricks.com/centering-css-complete-guide
 */
lite-youtube {
    background-color: #000;
    position: relative;
    cursor: pointer;
    max-width: inherit !important;
    margin: 20px 0 !important;
}

lite-youtube,
lite-youtube__button,
lite-youtube__preview,
lite-youtube__name {
    display: block;
}

lite-youtube.lite-youtube_420x236 {
    width: 420px !important;
    height: 236px !important;
}
lite-youtube.lite-youtube_560x315 {
    width: 560px !important;
    height: 315px !important;
}
lite-youtube.lite-youtube_640x360 {
    width: 640px !important;
    height: 360px !important;
}
lite-youtube.lite-youtube_853x480 {
    width: 853px !important;
    height: 480px !important;
}
lite-youtube.lite-youtube_1280x720 {
    width: 1280px !important;
    height: 720px !important;
}
lite-youtube.lite-youtube_420x315 {
    width: 420px !important;
    height: 315px !important;
}
lite-youtube.lite-youtube_480x360 {
    width: 480px !important;
    height: 360px !important;
}
lite-youtube.lite-youtube_640x480 {
    width: 640px !important;
    height: 480px !important;
}
lite-youtube.lite-youtube_960x720 {
    width: 960px !important;
    height: 720px !important;
}

lite-youtube.lite-youtube_16x9,
lite-youtube.lite-youtube_4x3 {
    width: 100% !important;
}

lite-youtube.lite-youtube_9x16::after,
lite-youtube.lite-youtube_16x9::after,
lite-youtube.lite-youtube_4x3::after {
    content: "";
    display: block;
}

lite-youtube.lite-youtube_16x9::after {
    padding-bottom: calc(100% / (16 / 9));
}

lite-youtube.lite-youtube_9x16 {
    width: 100%;
    max-width: 500px !important;
    margin-left: auto !important;
    margin-right: auto !important;
}

lite-youtube.lite-youtube_9x16:after {
    padding-bottom: calc(100% / (9 / 16));
}

lite-youtube.lite-youtube_4x3::after {
    padding-bottom: calc(100% / (4 / 3));
}

/* gradient */
lite-youtube::before {
    content: '';
    display: block;
    position: absolute;
    z-index: 11;
    top: 0;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==);
    background-position: top;
    background-repeat: repeat-x;
    height: 52px;
    /*padding-bottom: 50px;*/
    width: 100%;
    transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}

lite-youtube > iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 13;
    top: 0;
    left: 0;
}

/* play button */
lite-youtube > .lty-playbtn {
    width: 68px;
    height: 48px;
    position: absolute;
    transform: translate3d(-50%, -50%, 0);
    top: 50%;
    left: 50%;
    z-index: 11;
    /* YT's actual play button svg */
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 68 48"><path fill="%23f00" fill-opacity="0.8" d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"></path><path d="M 45,24 27,14 27,34" fill="%23fff"></path></svg>');
    filter: grayscale(100%);
    transition: filter .1s cubic-bezier(0, 0, 0.2, 1);
}

lite-youtube:hover > .lty-playbtn {
    filter: none;
}

/* Post-click styles */
lite-youtube.lyt-activated {
    cursor: unset;
}
lite-youtube.lyt-activated::before,
lite-youtube.lyt-activated > .lty-playbtn {
    opacity: 0;
    pointer-events: none;
}

lite-youtube .lite-youtube__preview {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

lite-youtube .lite-youtube__image {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    object-fit: cover;
    object-position: center;
    max-width: 100%;
    height: auto;
}

lite-youtube.lite-youtube_9x16 .lite-youtube__image {
    height: 100%;
}

lite-youtube .lite-youtube__name {
    position: absolute;
    z-index: 12; 
    left: 12px;
    top: 0;
    right: 12px;
    color: #fff;
    line-height: 52px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 0.8em;
}

/**
 * Fix bug with Gutenberg Block.
 */
.wp-block-embed.is-provider-youtube {
    .wp-block-embed__wrapper {
        &:before {
            display: none;
        }
    }
}